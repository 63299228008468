import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import GalleryNav from "../components/UI/GalleryNav"
import PhotoGallery from "../components/PhotoGallery"

import CallToAction from "../components/CallToAction"

// const query = graphql`
//   {
//     contentfulPondsGallery {
//       images {
//         gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
//         title
//         id
//       }
//     }
//   }
// `

export default function PhotosPage() {
  // const {
  //   contentfulPondsGallery: { images: imageList },
  // } = useStaticQuery(query)
  return (
    <Layout>
      <Navbar />
      <Seo
        title="Ponds | Photos"
        description="Our gallery of pond images will show you the natural style Andreatta Waterscapes specializes in including Koi ponds, water gardens and swimming ponds."
      />
      <GalleryNav />
      {/* <PhotoGallery
        title="Ponds"
        description="Whether it's a fish pond, a water garden or a natural pool, water has the ability to draw us to it and bring a sense of peace and tranquility that can truly make us feel at home in our own backyard oasis.  The following images are a sampling of some of the beautiful ponds which we have been priviledged to construct for our clients."
        imageList={imageList}
      /> */}
      <CallToAction />
    </Layout>
  )
}
