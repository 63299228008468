import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage } from "gatsby-plugin-image"

import { useTheme, makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Spacer from "./UI/SectionSpacer"

const useStyles = makeStyles(theme => ({
  h2: {
    fontSize: "2.0625rem",
    textTransform: "uppercase",
    fontWeight: 600,
    paddingBottom: "1.0375rem",
  },
  body: {
    color: theme.palette.common.muted,
    fontWeight: 300,
    paddingBottom: "2.5em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".9rem",
    },
  },
  galleryThumbnail: {
    "& img": {
      cursor: "pointer",
      padding: "0 1em",
      width: 400,
      height: 356,
      [theme.breakpoints.only("lg")]: {
        width: 356,
        height: 300,
      },
      [theme.breakpoints.down("md")]: {
        width: 256,
        height: 225,
      },
      [theme.breakpoints.down("xs")]: {
        width: 178,
        height: 133,
      },
    },
  },
  componentGalleryThumbnail: {
    "& img": {
      cursor: "pointer",
      padding: "0 1em",
      width: 356,
      height: 300,
      [theme.breakpoints.only("lg")]: {
        width: 356,
        height: 300,
      },
      [theme.breakpoints.down("md")]: {
        width: 256,
        height: 225,
      },
      [theme.breakpoints.down("xs")]: {
        width: 178,
        height: 133,
      },
    },
  },
  gallery: {
    paddingBottom: "3.0em",
  },
}))

export default function PhotoGallery({
  title,
  description,
  imageList,
  component = false,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <SRLWrapper>
      <Grid
        container
        justifyContent="center"
        style={{
          paddingLeft: matchesXS ? ".5rem" : "3.25rem",
          paddingRight: matchesXS ? ".5rem" : "3.25rem",
        }}
      >
        {/* <Grid item xs={12}>
          <Typography
            variant="h2"
            color="secondary"
            className={classes.h2}
            align="center"
            gutterBottom
          >
            {title}
          </Typography>
        </Grid> */}
        <Grid item xs={11} sm={10} md={6}>
          <Typography variant="body1" align="center" className={classes.body}>
            {description && description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" className={classes.gallery}>
            {imageList.map(img => {
              return (
                <Grid
                  key={img.id}
                  container
                  item
                  xs={6}
                  md={3}
                  justifyContent="center"
                  style={{ paddingTop: "2rem" }}
                >
                  <GatsbyImage
                    image={img.gatsbyImageData}
                    alt={img.title}
                    imgStyle={{ marginLeft: "auto", marginRight: "auto" }}
                    className={
                      component
                        ? classes.componentGalleryThumbnail
                        : classes.galleryThumbnail
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      {component && <Spacer />}
    </SRLWrapper>
  )
}
